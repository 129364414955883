import React from "react"
import styles from "../css/footer.module.css"

const Footer = () => {
  return (
    <footer className={styles.footer}>
      © {new Date().getFullYear()}
    </footer>
  )
}

export default Footer